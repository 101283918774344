import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NbAuthJWTToken, NbAuthService,NbLogoutComponent } from '@nebular/auth';
import{ GlobalVars } from './common/globals';
import * as Vincenty from 'node-vincenty';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  user: any;
  org_id: number;
  userId: number;
  suspended: boolean = false;
  t: NbAuthJWTToken;
  constructor(private httpClient: HttpClient,
    private authService: NbAuthService) {
      this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          this.user = token.getPayload();
          // here we receive a payload from the token and assigns it to our `user` variable
           console.log(JSON.stringify(this.user));
          this.org_id = this.user.org_id;
          this.userId = this.user.ID;
          if (this.user.suspended == true){
            this.suspended = true;
          }else{
            this.suspended = false;
          }
          GlobalVars.userId = this.userId;
          localStorage.setItem('bdcToken', token.toString());
        }

      });
    }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  url = 'https://www.regulatorysolutions.us';
  
  //org_id = 2;
  
  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


  public checkRegister(regobj){

    return this.httpClient.post(`${this.url}/register/checkRegister`, regobj );

  }

  public getFilerdata(){
    return this.httpClient.get(`${this.url}/data/getFilerinfo/` + this.org_id);
  }

  public updatefilerReady(status){
    return this.httpClient.get(`${this.url}/data/updateFilerReady/` + this.org_id + `/` + status) ;
  }

  public getfilerReady(period_end){
    return this.httpClient.get(`${this.url}/data/getFilerReady/` + this.org_id + '/' + `'${period_end}'`) ;
  }

  public getSettingsdata(){
    return this.httpClient.get(`${this.url}/data/getRfSettingsdata/` + this.org_id);
  }

  public getUserrole(rid){

    return this.httpClient.get(`${this.url}/data/getUserrole/` + rid);

  }

  public checkReferrals(){
    return this.httpClient.get(`${this.url}/register/getReferrers`);
  }

  public getRoles(){

    return this.httpClient.get(`${this.url}/data/getRoles/` + this.org_id);

  }

  public addRole(roleobj){

    return this.httpClient.post(`${this.url}/data/addRole/` + this.org_id, roleobj );

  }

  public updateRole(roleobj){

    return this.httpClient.post(`${this.url}/data/updateRole/` + this.org_id, roleobj );

  }

  public updateUser(usrobj){

    return this.httpClient.post(`${this.url}/data/updateUser/` + this.org_id, usrobj );

  }

  public getUsers(){

    return this.httpClient.get(`${this.url}/data/getUsers/` + this.org_id);

  }

  public checkUsername(u){

    return this.httpClient.get(`${this.url}/data/checkUsername/` + this.org_id + `/` + u);

  }

  public getTechnologies(){

    return this.httpClient.get(`${this.url}/data/getTechnologies`);

  }

  public addUser(usrobj){

    return this.httpClient.post(`${this.url}/data/addUser/` + this.org_id, usrobj );

  }

  public getFilerstatus(period_end){
    return this.httpClient.get(`${this.url}/data/getFilerstatus/` + this.org_id + '/' +`'${period_end}'`);
  }

  public getFilerservices(period_end){
    return this.httpClient.get(`${this.url}/data/getFilerservices/` + this.org_id + '/' +`'${period_end}'`);
  }

  public getAllPackages(){
    return this.httpClient.get(`${this.url}/data/getAllPackages/` + this.org_id);
  }
  
  public checkInsurance(){
    return this.httpClient.get(`${this.url}/data/checkInsurance/` + this.org_id);
  }

  public getPaidservices(period_end){
    return this.httpClient.get(`${this.url}/data/getPaidservices/` + this.org_id + `/'${period_end}'`);
  }

  public getSubscriptionfiles(period_end){
    return this.httpClient.get(`${this.url}/data/getSubscriptionfiles/` + this.org_id + `/'${period_end}'`);
  }

  public getSubscriberfiles(period_end){
    return this.httpClient.get(`${this.url}/data/getSubscriberfiles/` + this.org_id + `/'${period_end}'`);
  }

  public getSubscriberdata(period_end){
    return this.httpClient.get(`${this.url}/data/getSubscriberdata/` + this.org_id + `/'${period_end}'`);
  }

  public getSubscribers(){
    return this.httpClient.get(`${this.url}/data/getSubscribers/` + this.org_id );
  }

  public checkPending(){
    return this.httpClient.get(`${this.url}/data/checkPending/` + this.org_id );
  }

  public getPendingSubscribers(){
    return this.httpClient.get(`${this.url}/data/getPendingSubscribers/` + this.org_id );
  }

  public approvePendingSubscribers(period){
    return this.httpClient.get(`${this.url}/data/approvePendingSubscribers/` + this.org_id + `/` + period );
  }

  public saveSubscriber(sub,newsub,pending){
    return this.httpClient.post(`${this.url}/data/saveSubscriber/` + this.org_id + '/' + newsub + '/' + pending, sub );
  }

  public getAuctiondata(poly){
    return this.httpClient.post(`${this.url}/data/getAuctiondata/` + this.org_id, poly );
  }

  public getWireddatafiles(period_end){
    return this.httpClient.get(`${this.url}/data/getWireddatafiles/` + this.org_id + `/'${period_end}'`);
  }

  public getCnheatdatafiles(period_end){
    return this.httpClient.get(`${this.url}/data/getCnheatdatafiles/` + this.org_id + `/'${period_end}'`);
  }

  public checkout(packages, period_end){
    return this.httpClient.post(`${this.url}/payment/checkout/` + this.org_id + '/' + period_end, packages);
  }

  public addPackages(packages, period_end){
    return this.httpClient.post(`${this.url}/payment/addPackages/` + this.org_id + '/' + period_end, packages);
  }

  public addShapes(shapes){
    return this.httpClient.post(`${this.url}/data/addShapes/` + this.org_id + '/' + this.userId, shapes);
  }

  public getUserLayers(shapes){
    return this.httpClient.get(`${this.url}/admin/getUserLayers/` + this.org_id);
  }

  public getFileruploadstatus(period_end){
    return this.httpClient.get(`${this.url}/data/getFileruploadstatus/` + this.org_id + `/'${period_end}'`);
  }

  public updateFilerstatus(type, status,period_end,user){
    return this.httpClient.post(`${this.url}/data/updateFilerstatus/` + this.org_id + '/' + type + '/' + status + '/' + user + `/'${period_end}'`, null);
  }

  public getPathprofiles(aps,lat,lon,elv,resolution,loc,ptype){
    if (ptype == 'map'){
      
      return this.httpClient.post(`${this.url}/data/getPathprofilesnew/` + this.org_id + `/` + lat + `/` + lon + `/` + elv + `/` + resolution , aps);
    }else{
      return this.httpClient.post(`${this.url}/admin/getPathprofilesmongo/` + this.org_id + `/` + lat + `/` + lon + `/` + elv + `/` + resolution + `/` + loc , aps);
    }
  }
  public getLinksubscriber(customer,fid){
    return this.httpClient.post(`${this.url}/data/getLinksubscriber/` + this.org_id + `/` + customer + `/` + fid , null);

  }

  public cancelCart(){
    return this.httpClient.post(`${this.url}/payment/cancelCart/` + this.org_id, null);
  }

  public deleteFile(filetype,file,org,period){
    console.log('deleting ' + file + ' ' + period );
    return this.httpClient.post(`${this.url}/data/deleteFile/` + this.org_id + '/' + filetype + '/' + file + '/' + `'${period}'`, null);
  }

  public downloadFile(file,filetype,period_end){
    const headers = new HttpHeaders().append('responseType', 'blob');
    

    const head = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.google-earth.kml+xml',
        responseType: 'blob',
        observe : 'response',
        accept: 'application/vnd.google-earth.kml+xml'
      }),
    };
    console.log("getting file");
    
    return this.httpClient.get(`${this.url}/data/downloadFile/` +  this.org_id + '/' + filetype + '/' + `'${period_end}'` + '/' + file, head);
  }

  public saveFilerdata(filer){
    console.log('saving filer data ' + JSON.stringify(filer));
    return this.httpClient.post(`${this.url}/data/saveFilerdata/` + this.org_id, filer);
  }

  public saveSettingsdata(settings,user){
    console.log('saving settings data ' + JSON.stringify(settings));
    return this.httpClient.post(`${this.url}/data/saveSettingsdata/` + this.org_id + '/' + user, settings);
  }

  public saveRfSettingsdata(settings,user){
    console.log('saving settings data ' + JSON.stringify(settings));
    return this.httpClient.post(`${this.url}/data/saveRfSettingsdata/` + this.org_id + '/' + user, settings);
  }

  public completeSettings(status,user,period){
    return this.httpClient.post(`${this.url}/data/completeSettings/` + this.org_id + '/' + status + '/' + period,null);
  }

  public deleteThroughput(throughput,user){

    return this.httpClient.post(`${this.url}/data/removeThroughput/` + this.org_id + '/' + user, throughput);
  }

  public getHexgrids(level,poly){
    return this.httpClient.post(`${this.url}/data/getHexgrids/` + level, poly);
  }

  public exportBdc(){

    return this.httpClient.get(`${this.url}/export/exportBdcKml/` + this.org_id);
  }

  public addContact(contact){
    return this.httpClient.post(`${this.url}/data/addContact/` + this.org_id, contact);
  }

  public updateContact(contact){
    return this.httpClient.post(`${this.url}/data/updateContact/` + this.org_id, contact);
  }

  public getGovtypes(){
    return this.httpClient.get(`${this.url}/data/getGovTypes/` + this.org_id);
  }

  public getContacts(){
    return this.httpClient.get(`${this.url}/data/getContacts/` + this.org_id);
  }

  public getOrgdata(){
    return this.httpClient.get(`${this.url}/company/getOrg/` + this.org_id);
  }

  public addWisp(wispobj){
    return this.httpClient.post(`${this.url}/addWisp/`,wispobj);
  }

  public updateOrg(orgobj){
    return this.httpClient.post(`${this.url}/company/updateOrg/` + this.org_id,orgobj);
  }

  public updateOrginfo(orgobj){
    return this.httpClient.post(`${this.url}/company/updateOrginfo/` + this.org_id,orgobj);
  }

  public requestPass(user){
    return this.httpClient.post(`${this.url}/auth/requestPass/` + user,{});

  }

  public getClutter(){
    return this.httpClient.get(`${this.url}/data/getClutter/` + this.org_id);
  }

  public saveClutter(user,clutter){

    return this.httpClient.post(`${this.url}/data/updateClutter/` + this.org_id + '/' + user,clutter);

  }

  public addClutter(user,clutter){

    return this.httpClient.post(`${this.url}/data/addClutter/` + this.org_id + '/' + user,clutter);

  }

  public getSites(){
    return this.httpClient.get(`${this.url}/data/getSites/` + this.org_id);
  }

  public getAntennas(){
    return this.httpClient.get(`${this.url}/data/getAntennas/` + this.org_id);
  }

  public getNetworkBounds(){
    return this.httpClient.get(`${this.url}/data/getNetworkBounds/` + this.org_id );
  }

  public getTileJson(tilename){
    return this.httpClient.get(`${this.url}/data/getTileJson/` + tilename );
  }

  public getSiteAps(site){
    return this.httpClient.get(`${this.url}/data/getSiteAps/` + this.org_id + '/' + site);
  }

  public getSitetypes(){
    return this.httpClient.get(`${this.url}/data/getSitetypes/` + this.org_id);
  }

  public addSite(user,siteobj){
    return this.httpClient.post(`${this.url}/data/addSite/`+ this.org_id + '/' + user,siteobj);
  }


  public updateSite(user,siteobj){
    return this.httpClient.post(`${this.url}/data/updateSite/` + this.org_id + '/' + user,siteobj);
  }

  public completeSites(user,siteobj){
    return this.httpClient.post(`${this.url}/data/completeSites/` + this.org_id + '/' + user,siteobj);
  }

  public deleteSite(user,siteobj){
    return this.httpClient.post(`${this.url}/data/deleteSite/`+ this.org_id + '/' + user ,siteobj);
  }

  public deleteAp(user,apobj){
    return this.httpClient.post(`${this.url}/data/deleteAp/`+ this.org_id + '/' + user ,apobj);
  }

  public getSingleplan(planid){
    return this.httpClient.get(`${this.url}/getPlan/` + this.org_id + '/' + planid );
  }

  public updatePlan(planobj){
    return this.httpClient.post(`${this.url}/updatePlan/` + this.org_id, planobj );
  }
  
  public addPlan(planobj){
    return this.httpClient.post(`${this.url}/addPlan/` + this.org_id, planobj );
  }

  public deletePlan(planobj){
    return this.httpClient.post(`${this.url}/deletePlan/` + this.org_id, planobj );
  }

  savePlanfeature(f){
    return this.httpClient.post(`${this.url}/updatePlanfeature/` + this.org_id + '/' + f.id + '/' + f.feature, this.httpOptions);
  }

  addPlanfeature(f){
    return this.httpClient.post(`${this.url}/addPlanfeature/` + this.org_id, f);
  }

  public getLeads(){
    return this.httpClient.get(`${this.url}/customers/` + this.org_id);
  }

  public addLead(leadobj){
    return this.httpClient.post(`${this.url}/addLead/` + this.org_id, leadobj);
  }

  public deleteLead(leadobj){
    return this.httpClient.post(`${this.url}/deleteLead/` + this.org_id, leadobj);
  }

  public getFrequencies(){
    return this.httpClient.get(`${this.url}/frequencies/` + this.org_id);
  }

  public addFrequency(freqobj){
    return this.httpClient.post(`${this.url}/addFrequency/` + this.org_id, freqobj);
  }

  public updateFrequency(freqobj){
    return this.httpClient.post(`${this.url}/updateFrequency/` + this.org_id, freqobj);
  }

  public deleteFrequency(freqobj){
    return this.httpClient.post(`${this.url}/deleteFrequency/` + this.org_id, freqobj);
  }

  public getMfgs(){
    return this.httpClient.get(`${this.url}/mfgs/` + this.org_id);
  }

  public getInvoices(){
    return this.httpClient.get(`${this.url}/payment/checkInvoices/` + this.org_id);
  }

  

  public getMfg(mfgid){
    return this.httpClient.get(`${this.url}/mfg/` + this.org_id, mfgid);
  }

  public addMfg(mfgobj){
    return this.httpClient.post(`${this.url}/addMfg/` + this.org_id, mfgobj);
  }

  public updateMfg(mfgobj){
    return this.httpClient.post(`${this.url}/updateMfg/` + this.org_id, mfgobj);
  }

  public deleteMfg(mfgobj){
    return this.httpClient.post(`${this.url}/deleteMfg/` + this.org_id, mfgobj);
  }

  public registerWisp(wispobj){
    console.log("registering a new company");
    return this.httpClient.post(`${this.url}/register/`, wispobj );
  }

  public getModels(){
    
    return this.httpClient.get(`${this.url}/getModels/` + this.org_id );
  }

  public addModel(modelobj){
    
    return this.httpClient.post(`${this.url}/addModel/` + this.org_id, modelobj );
  }

  public deleteModel(modelobj){
    //need to add deleteModel to backend
    return this.httpClient.post(`${this.url}/deleteModel/` + this.org_id, modelobj );
  }

  public getInventory(){
    
    return this.httpClient.get(`${this.url}/getInventory/` + this.org_id );
  }
  
  public getInventoryitem(id){
    
    return this.httpClient.get(`${this.url}/getInventoryitem/` + this.org_id + "/" + id );
  }

  public addInventoryitem(invobj){
    console.log("invobj" + JSON.stringify(invobj));
    return this.httpClient.post(`${this.url}/addInventoryitem/` + this.org_id, invobj);
  }

  public getAps(mode,id){
    
    return this.httpClient.get(`${this.url}/data/getAps/` + this.org_id );
  }

  public addAp(user,apobj){
    
    return this.httpClient.post(`${this.url}/data/addAp/` + this.org_id + '/' + user, apobj);
  }

  public updateAp(user,apobj){
    
    return this.httpClient.post(`${this.url}/data/updateAp/` + this.org_id + '/' + user, apobj);
  }

  public completeAps(user,apobj){
    
    return this.httpClient.post(`${this.url}/data/completeAps/` + this.org_id + '/' + user, apobj);
  }

  public getSingleap(apid){
    
    return this.httpClient.get(`${this.url}/data/getSingleap/` + this.org_id + `/` + apid);
  }

  public getSinglesite(siteid){
    
    return this.httpClient.get(`${this.url}/data/getSinglesite/` + this.org_id + `/` + siteid);
  }

  public getApiSettings(){
    return this.httpClient.get(`${this.url}/data/getApiSettings/` + this.org_id );
  }

  public saveApiSettings(s){
    return this.httpClient.post(`${this.url}/data/saveApiSettings/` + this.org_id, s );
  }

  public geocodeOSM(addr){
    var url = `https://nominatim.openstreetmap.org/search?format=json&q=` + addr ;
    return this.httpClient.get(url);
  }

  public findAPs(lat,lon){
    return this.httpClient.get(`${this.url}/findAPs/` + lat + "/" + lon + "/ap/" + this.org_id);
  }

  public qualify(lat,lon){
    var headers = new HttpHeaders()
    .append('Cache-Control','no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
    .append('Pragma','no-cache')
    .append('Expires','0');
    
    var result = this.httpClient.get( `${this.url}/qualify/` + lat + "/" + lon + "/ap/" + this.org_id, {headers: headers});
    return result;
  }

  public ptpqualify(lat1,lon1,elv1,lat2,lon2,elv2,dist){
    return this.httpClient.get(`${this.url}/qualify_ptp/` + lat1 + "/" + lon1 + "/" + elv1 + "/" + lat2 + "/" + lon2 +"/"+elv2+"/"+dist);
  }


  public getVincenty(lat1,lon1,lat2,lon2){
    var vinc = Vincenty.distVincenty(lat1, lon1, lat2, lon2);
    return vinc;
  }




  //from rsi-admin
  public getAdminSites(org_id){
    return this.httpClient.get(`${this.url}/admin/getAdminSites/` + org_id);
  }

  public getAdminAps(org_id:number){
    
    return this.httpClient.get(`${this.url}/admin/getAdminAps/` + org_id );
  }

  public getAdminNetworkBounds(org_id){
    return this.httpClient.get(`${this.url}/admin/getAdminNetworkBounds/` + org_id );
  }

  public getAdminFiberBounds(org_id){
    return this.httpClient.get(`${this.url}/admin/getAdminFiberBounds/` + org_id );
  }

  public getAdminCableBounds(org_id){
    return this.httpClient.get(`${this.url}/admin/getAdminCableBounds/` + org_id );
  }

  public getAdminDslBounds(org_id){
    return this.httpClient.get(`${this.url}/admin/getAdminDslBounds/` + org_id );
  }

  public getAdminCopperBounds(org_id){
    return this.httpClient.get(`${this.url}/admin/getAdminCopperBounds/` + org_id );
  }



  public getAdminFilerInfo(org_id){
    return this.httpClient.get(`${this.url}/admin/getAdminFilerInfo/` + org_id );
  }

  public getTotalLocations(org_id){
    return this.httpClient.get(`${this.url}/admin/getTotalLocations/` + org_id );
  }

  public getServicedLocations(org_id){
    return this.httpClient.get(`${this.url}/admin/getServicedLocations/` + org_id );
  }

  public getFiberServicedLocations(org_id){
    return this.httpClient.get(`${this.url}/admin/getFiberServicedLocations/` + org_id );
  }

  public getCableServicedLocations(org_id){
    return this.httpClient.get(`${this.url}/admin/getCableServicedLocations/` + org_id );
  }

  public getDslServicedLocations(org_id){
    return this.httpClient.get(`${this.url}/admin/getDslServicedLocations/` + org_id );
  }

  public getCopperServicedLocations(org_id){
    return this.httpClient.get(`${this.url}/admin/getCopperServicedLocations/` + org_id );
  }

  public getcnheatServicedLocations(org_id){
    return this.httpClient.get(`${this.url}/admin/getcnheatServicedLocations/` + org_id );
  }

  public getLocationAps(org_id, loc){
    return this.httpClient.get(`${this.url}/admin/getLocationAps/` + org_id + '/' + loc);
  }

  public getcnHeatraw(org_id){
   
    return this.httpClient.get(`${this.url}/admin/getcnHeatobs/` + org_id );
  }

  public locationSearch(org_id,term){
   
    return this.httpClient.get(`${this.url}/data/searchLocations/` + org_id + '/' + term);
  }

  public subscriberSearch(org_id,term){
   
    return this.httpClient.get(`${this.url}/data/searchSubscribers/` + org_id + '/' + term);
  }

  public addressSearch(org_id,term){
   
    return this.httpClient.get(`${this.url}/data/bingautosuggest/` + org_id +'/' + term);
  }

  public getBingLocation(term){
   
    return this.httpClient.get(`${this.url}/data/bingAddressLookup/` + term);
  }

  public getMapSettings(org_id){
    return this.httpClient.get(`${this.url}/data/getMapSettings/` + org_id);

  }

  public saveMapSettings(org_id, mapcenter,maprad){
    return this.httpClient.post(`${this.url}/data/saveMapSettings/` + org_id + '/' + mapcenter + '/' + maprad, null);

  }


  //common funcitons for mapping 

  //finds angle between two other lines
  public angleBetween(n,a,b){
    
    n = (360 + (n % 360)) % 360;
    a = (3600000 + a) % 360;
    b = (3600000 + b) % 360;

    if (a < b)
            return a <= n && n <= b;
    return a <= n || n <= b;


  }

  //get aps within range
  public findAPslocal(lat,lon,apobj){
    var apdistance = new Array();
    console.log("here");

    var incov = false;

    var insectcnt = 0;
    var aps = new Array();

    var sc = 0;
    for (var i in apobj){
      //alert(apobj[i].coords);
      var sectcolor = "#00FF00";


      incov = false;

        var aplat = parseFloat(apobj[i].lat);
      var aplon = parseFloat(apobj[i].lon);
      //var ept = new OpenLayers.Geometry.Point(latlng.lon, latlng.lat).transform(proj_2, proj_1);


        var distvinc = Vincenty.distVincenty(aplat, aplon, parseFloat(lat), parseFloat(lon));
        apdistance[i] = distvinc.distance / 1000;
        console.log(apobj[i].ap_name + ", " + Number(apobj[i].coverage_radius_mi)* 1.609344 + ", " + apdistance[i]);
        if (apdistance[i] <= Number(apobj[i].coverage_radius_mi)* 1.609344){

            //see if geocoded point is within bw of antenna
            var ang1 = Number(apobj[i].azimuth) - Number(apobj[i].beamwidth) /2;

            var ang2 = Number(apobj[i].azimuth) + Number(apobj[i].beamwidth) /2;
            //var heading = google.maps.geometry.spherical.computeHeading(apobj[i].coords,latlng);

            var heading = distvinc.initialBearing;
            if(heading < 0){
              heading = 360 + heading;
            }

            if (apobj[i].beamwidth == 360){
              incov = true;
            }else{
              incov = this.angleBetween(heading,ang1,ang2);
            }

            if(incov){
                apobj[i].bearing = heading;
                aps.push(apobj[i]);


             }

     }

   }

   return aps;


  }

  //find towers within a given radius of a customer point
  public findTowers(lat,lng, towers, radius){
    var twrdistance = new Array();
    var incov = false;

    var insectcnt = 0;
    var twrs = new Array();

    var sc = 0;
    //var radius = 7;
    for (var i in towers){
      //alert(apobj[i].coords);
      var sectcolor = "#00FF00";


      incov = false;

      var twrlat = parseFloat(towers[i].latitude);
      var twrlon = parseFloat(towers[i].longitude);
      //var ept = new OpenLayers.Geometry.Point(latlng.lon, latlng.lat).transform(proj_2, proj_1);


        var distvinc = Vincenty.distVincenty(twrlat, twrlon, parseFloat(lat), parseFloat(lng));
        twrdistance[i] = distvinc.distance / 1000;
        //console.log(apobj[i].name + ", " + Number(apobj[i].radius)* 1.609344 + ", " + apdistance[i]);
        if (twrdistance[i] <= Number(radius)* 1.609344){

            var heading = distvinc.initialBearing;

            incov = true;

            if(incov){
                twrs.push(towers[i]);
            }

        }

    }


    return twrs;
  }

  public sectorShapes(ap, mode,contact){
    var radius;
    if (mode == 0){
      radius = ap.coverage_radius_mi;
    }else{
      radius = 0.75;
    }

    var clat;
    var clon;
    if (contact){
        clat = contact.lat;
        clon = contact.lon;
    }
    var aplat = Number(ap.lat);
    var aplon = Number(ap.lon);

    var startprof = [aplat,aplon];
    var endprof = [Number(clat),Number(clon)];

    var erdist = radius * 1.609344*1000;
    var ang1 = ap.azimuth - ap.beamwidth /2;

    var ang2 = ap.azimuth + ap.beamwidth /2;

    var vinc = Vincenty.destVincenty(aplat, aplon, ang1, erdist);


    var endrad1 = [vinc.lat,vinc.lon];

    var vinc2 = Vincenty.destVincenty(aplat, aplon, Number(ang2), erdist);
    var endrad2 =[vinc2.lat,vinc2.lon];

    var llarr = new Array();
    //llarr[0] = apobj[i].coords;
    if (ap.bw == 360){
      llarr[0] = endrad1;
      llarr[1] = endrad1;
      }else{
      llarr[0] = [Number(ap.lat), Number(ap.lon)];
      llarr[1] = endrad1;
    }


    var npts = 101;

    for (var j=2;j<npts;j++){
      var newang = ang1 + (j*(ap.beamwidth/(npts-1)));

      var vinc = Vincenty.destVincenty(aplat, aplon, newang, erdist);

      llarr[j] =  [vinc.lat, vinc.lon];

    }

    return llarr;


  }
}
